@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;0,700;0,800;1,600;1,700&family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.makeFadeOut {
	-webkit-animation: fadeOut 2s;
	        animation: fadeOut 2s;
  opacity: 0;
  height: 0;
}

@-webkit-keyframes fadeOut {
  from {
  	opacity: 1;
    height: inherit;
  }
  to {
 	  opacity: 0;
     height: 0;
    }
}

@keyframes fadeOut {
  from {
  	opacity: 1;
    height: inherit;
  }
  to {
 	  opacity: 0;
     height: 0;
    }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);transform: translateY(-15px);}
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation: bounce 0.8s infinite alternate;
          animation: bounce 0.8s infinite alternate;
}

